import { useMemo } from 'react'
import * as THREE from 'three'

import heightFn from './heightFn'
const segmentsPerTile = 128

const grass = THREE.ImageUtils.loadTexture( "img/grass.jpg" )
grass.wrapS = THREE.RepeatWrapping;
grass.wrapT = THREE.RepeatWrapping;
grass.repeat.set(200/5,200/5)

function createPlane({ size, heights }) {
  const geometry = new THREE.PlaneBufferGeometry(size, size, segmentsPerTile, segmentsPerTile)
  updatePlane({ geometry, size, heights })
  return geometry
}

function updatePlane({ geometry, size, heights }) {
  const vertices = heights.flatMap((row, i) => row.flatMap((z, j) => [i * size / segmentsPerTile, j * size / segmentsPerTile, z]))
  geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
  geometry.computeBoundingSphere()
  geometry.computeFaceNormals()
  geometry.computeVertexNormals()
}

function generateHeightmap({
  size, xOffset, zOffset, road
}) {
  const data = []

  const elementSize = size / segmentsPerTile

  for (let i = 0; i <= segmentsPerTile; i++) {
    const row = []
    for (let j = 0; j <= segmentsPerTile; j++) {
      const x = (xOffset + i * elementSize)
      const z = (zOffset + j * elementSize)

      row.push(heightFn(x,z))
    }
    data.push(_.reverse(row))
  }

  return data
}

export default function Ground() {
  const size = 400
  const xOffset = -200
  const zOffset = -200

  const geometry = useMemo(() => {
    const heights = generateHeightmap({ size, xOffset, zOffset })
    return createPlane({ size, heights })
  }, [])

  return <mesh geometry={geometry} rotation={[-Math.PI / 2, 0, 0]} position={[xOffset,0,size + zOffset]}>
    <meshPhongMaterial map={grass} side={THREE.DoubleSide} shininess={0.2} />
  </mesh>
}