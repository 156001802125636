// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import ReactDOM from 'react-dom'

import Forest from 'Mixed3D/components/Forest'
import 'PhysicsExperiments/styles.scss'

console.log('hello')

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Forest />,
    document.body.appendChild(document.createElement('div')),
  )
})
