import { Water } from 'three/examples/jsm/objects/Water.js'
import { Sky } from 'three/examples/jsm/objects/Sky.js'

import * as THREE from 'three'
import { useMemo } from 'react'
import { useFrame, useThree } from 'react-three-fiber'

const inclination = 0.0
const azimuth = 0.205

function getLighting(inclination, azimuth) {
  const turbidity = 0.8 + inclination * 19
  const mieCoefficient = Math.max(0, 0.05 * (0.5 - inclination) * 4)
  const mieDirectionalG = inclination < 0.47 ? 1 : 0.2 * (0.5 - inclination) * 4
  const rayleigh = 1 + 3 * inclination // More rayleigh scattering the closer the sun is to horizon
  const directionalIntensity = Math.max(0.05, 0.2 + (0.5 - inclination) * 1.8)
  const hemisphereIntensity = Math.max(0.05, 0.1 + (0.5 - inclination))
  const sunColor = 0xddeeff
  const luminance = Math.max(0, Math.round(75 - inclination * 150))
  const fogColor = `hsl(0, 0%, ${luminance}%)`

  return {
    turbidity,
    rayleigh,
    mieCoefficient,
    mieDirectionalG,
    sunColor,
    directionalIntensity,
    hemisphereIntensity,
    fogColor
  }
}

export default function Environment({ inclination, waterHeight = 0, distortionScale = 3.7, planeSize = 100000 }) {
  const { gl, scene } = useThree()
  const {
    turbidity,
    rayleigh,
    mieCoefficient,
    mieDirectionalG,
    sunColor,
    directionalIntensity,
    hemisphereIntensity,
    fogColor
  } = useMemo(() => getLighting(inclination, azimuth), [inclination, azimuth])

  const [water, sky, sunPos] = useMemo(() => {
    const sky = new Sky();
    sky.scale.setScalar( 100000 );
    const uniforms = sky.material.uniforms;

    uniforms[ 'turbidity' ].value = turbidity;
    uniforms[ 'rayleigh' ].value = rayleigh;
    uniforms[ 'mieCoefficient' ].value = mieCoefficient;
    uniforms[ 'mieDirectionalG' ].value = mieDirectionalG;

    const waterGeometry = new THREE.PlaneBufferGeometry( planeSize, planeSize );
    const pmremGenerator = new THREE.PMREMGenerator( gl );

    const water = new Water(
      waterGeometry,
      {
        textureWidth: 2048,
        textureHeight: 2048,
        waterNormals: new THREE.TextureLoader().load( 'img/waternormals.jpg', function ( texture ) {

          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

        } ),
        alpha: 1,
        sunDirection: new THREE.Vector3(),
        sunColor,
        waterColor: 0x0f0f0f,
        distortionScale,
        fog: true
      }
    );

    const theta = Math.PI * ( inclination - 0.5 );
    const phi = 2 * Math.PI * ( azimuth - 0.5 );

    const sunX = Math.cos( phi );
    const sunY = Math.sin( phi ) * Math.sin( theta );
    const sunZ = Math.sin( phi ) * Math.cos( theta );

    const sunPos = [sunX, sunY, sunZ]

    water.rotation.x = - Math.PI / 2
    water.position.y = waterHeight
    water.material.uniforms[ 'sunDirection' ].value.copy( new THREE.Vector3(...sunPos) );
    sky.material.uniforms[ 'sunPosition' ].value.copy( new THREE.Vector3(...sunPos) );

    scene.environment = pmremGenerator.fromScene( sky ).texture
    scene.fog = new THREE.Fog(new THREE.Color(fogColor), 50000, 75000)

    return [water, sky, sunPos]
  }, [inclination])

  useFrame(() => {
    var time = performance.now() * 0.001;
    water.material.uniforms[ 'time' ].value += 1.0 / 300.0;
  })

  return <>
    <primitive object={water} />
    <primitive object={sky} />
    <directionalLight
      color="hsl(0.1, 100%, 95%)"
      position={sunPos}
      intensity={directionalIntensity}
      />
    <hemisphereLight skyColor={0x0000ff} groundColor={0xffffff} intensity={hemisphereIntensity} />
  </>
}

