import { useThree, useFrame } from 'react-three-fiber'
import { PointerLockControls, PerspectiveCamera } from "@react-three/drei"
import * as THREE from 'three'
import heightFn from './heightFn'

const vec = new THREE.Vector3(0,0,0)

export default function FirstPersonCamera() {
  const mouseReticle = useRef()
  const controlsRef = useRef()
  const keyPressed = useRef({})
  const isLocked = useRef(false)

  useEffect(() => {
    document.addEventListener( 'keydown', handleKeyDown, false );
    document.addEventListener( 'keyup', handleKeyUp, false );
  }, [])

  const handleKeyDown = (e) => {
      if (!keyPressed.current[e.key]) {
          keyPressed.current[e.key] = new Date().getTime();
      }
  };

  const handleKeyUp = (e) => {
      delete keyPressed.current[e.key];

      if (e.key === 'q') {
        act.local.upsert({ modal: 'screenSharing' })
      } else if (e.key === 'x') {
        act.local.upsert({ modal: null })
      }
  };

  const { camera, mouse } = useThree();
  // initialize camera to look at origin.
  useEffect(() => {
    camera.lookAt(0, 1.5, -3);
    camera.position.set(50,heightFn(50,50)+1.5,50)
  }, [camera]);

  const lastLookDirection = useRef({})

  useFrame((state, delta) => {
    const worldDirection = camera.getWorldDirection(vec)
    const { x,y,z } = worldDirection
    if (!_.isEqual({x, y, z}, lastLookDirection.current)) {
      lastLookDirection.current = { x,y,z }
    }

    Object.entries(keyPressed.current).forEach((e) => {
        const [key, start] = e;
        const duration = new Date().getTime() - start;

        // increase momentum if key pressed longer
        let momentum = Math.sqrt(duration + 200) * 0.001 + 0.05;

        // adjust for actual time passed
        momentum = momentum * delta / 0.016;

        switch (key) {
            case 'w': camera.translateZ(-momentum); break;
            case 's': camera.translateZ(momentum); break;
            case 'd': camera.translateX(momentum); break;
            case 'a': camera.translateX(-momentum); break;
            default:
        }

        // Bound the movement
        camera.position.y = heightFn(camera.position.x, camera.position.z) + 1.5
    });
  })

  return <>
    <PerspectiveCamera makeDefault position={[2, 1.5, 5]} near={0.1} far={1000}>
    </PerspectiveCamera>
    <PointerLockControls
      onUpdate={() => {
        if (controlsRef.current) {
          controlsRef.current.addEventListener('lock', () => {
            console.log('lock');
            isLocked.current = true
          });
          controlsRef.current.addEventListener('unlock', () => {
            console.log('unlock')
            isLocked.current = false;
          });
        }
      }}
      ref={controlsRef}
    />
  </>
}
