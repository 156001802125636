import { seed, perlin2 } from 'PhysicsExperiments/perlin'

const horizontalScale = 0.04
const xOffset = 0
const zOffset = 0

function maxHeightFn(x, z) {
  return 15
}

export default function heightFn(xRaw,zRaw) {
  const x = xRaw * horizontalScale + xOffset
  const z = zRaw * horizontalScale + zOffset

  const d = perlin2(x, z) / 2 + 0.5
  const macro = 0

  return (d + macro) * maxHeightFn(x,z) - 0.5 * (Math.abs(x) + Math.abs(z)) ** 2
}
