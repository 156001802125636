import { Suspense, useMemo } from 'react'
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { extend, Canvas, useFrame, useThree } from 'react-three-fiber'
import * as THREE from 'three'
import TreeGen from './proctree'
import Ground from './Ground'
import heightFn from './heightFn'
import FirstPersonCamera from './FirstPersonCamera'
import Environment from 'PhysicsExperiments/components/Environment'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
extend({ OrbitControls })

const trunkTexture = THREE.ImageUtils.loadTexture( "img/trunk.jpg" )
trunkTexture.wrapS = THREE.RepeatWrapping
trunkTexture.wrapT = THREE.RepeatWrapping

const leavesTexture = THREE.ImageUtils.loadTexture( "img/leaves.png" )
const waterHeight = -2

function getTreePositions (n, size) {
  const trees = []

  while(trees.length < n) {
    const x = (Math.random() * 2 - 1) * size / 2
    const z = (Math.random() * 2 - 1) * size / 2
    const y = heightFn(x,z) - 0.1

    if (y > waterHeight - 0.5) { // Stop trees being placed under water
      trees.push([x,y,z])
    }
  }

  return trees
}

function newTreeGeometry(tree, isTwigs) {
  var output = new THREE.Geometry();

  tree[ isTwigs ? 'vertsTwig' : 'verts'].forEach(function(v) {
    output.vertices.push(new THREE.Vector3(v[0], v[1], v[2]));
  });

  var uv = isTwigs ? tree.uvsTwig : tree.UV;
  tree[ isTwigs ? 'facesTwig' : 'faces'].forEach(function(f) {
    output.faces.push(new THREE.Face3(f[0], f[1], f[2]));
    output.faceVertexUvs[0].push(f.map(function(v) {
      return new THREE.Vector2(uv[v][0], uv[v][1]);
    }));
  });

  output.computeFaceNormals();
  output.computeVertexNormals(true);

  return output;
}

function Tree({ position }) {
  const [trunkMesh, twigsMesh] = useMemo(() => {
    const tree = new TreeGen({
      "seed": Math.random(),
      "segments": 10,
      "levels": 5,
      "vMultiplier": 2.36,
      "twigScale": 0.39,
      "initalBranchLength": 0.8,
      "lengthFalloffFactor": 0.85,
      "lengthFalloffPower": 0.99,
      "clumpMax": 0.454,
      "clumpMin": 0.404,
      "branchFactor": 2.45,
      "dropAmount": -0.1,
      "growAmount": 0.235,
      "sweepAmount": 0.01,
      "maxRadius": 0.139,
      "climbRate": 0.371,
      "trunkKink": 0.093,
      "treeSteps": 5,
      "taperRate": 0.947,
      "radiusFalloffRate": 0.73,
      "twistRate": 3.02,
      "trunkLength": 2.5 + Math.random() * 3
    })

    const trunkGeo = newTreeGeometry(tree);
    const trunkMaterial = new THREE.MeshLambertMaterial( { color: 0xdddddd, wireframe: false, map: trunkTexture } );
    const trunkMesh = new THREE.Mesh(trunkGeo, trunkMaterial);

    const twigsGeo = newTreeGeometry(tree, true);
    const twigsMaterial = new THREE.MeshLambertMaterial( { color: 0x999999, wireframe: false, map: leavesTexture, transparent: true, side: THREE.DoubleSide, alphaTest: 0.5 } );
    const twigsMesh = new THREE.Mesh(twigsGeo, twigsMaterial);

    return [trunkMesh, twigsMesh]
  }, [])

  return <group position={position}>
    <primitive object={trunkMesh} />
    <primitive object={twigsMesh} />
  </group>
}

function GLScene({ refs }) {
  const { camera, scene, gl: { domElement } } = useThree()

  useFrame(({ clock }) => {
    const { elapsedTime } = clock
  })

  const treePositions = useMemo(() => getTreePositions(100, 400), [])

  return <>
    <orbitControls args={[camera, domElement]} />
    <Environment inclination={0.48} waterHeight={waterHeight} distortionScale={0.37} planeSize={1000} />
    <FirstPersonCamera />

    <Ground />
    { treePositions.map((pos, i) => <Tree key={i} position={pos} />) }
  </>
}

export default function Forest() {
  const style = { position: 'absolute', height: '100vh', width: '100vw' }

  return <>
    <div style={{ ...style }} >
      <Canvas
        camera={{ position: [50, heightFn(50,50) + 1.5,50], far: 100000 }}
        colorManagement={false}
        gl={{ logarithmicDepthBuffer: true, outputEncoding: THREE.LinearEncoding }}
        >
        <GLScene />
      </Canvas>
    </div>
  </>
}